import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {React, useState,useEffect} from 'react';
import './App.css';
import { Helmet } from "react-helmet";
import HomepagePage from './Pages/Homepage';
import ContactpagePage from './Pages/ContactPage';
import Navbar from './Components/Navbar/Navbar';
import LoginPage from './Pages/LoginPage';
import ProjectsPagepage from './Pages/ProjectsPage';
import SupportPagepage from './Pages/SupportPage';
import MobileNavbar from './Components/MobileNavbar/MobileNavbar';
import CreateUserPage from './Pages/CreateUserPage';
import UserDashboardPage from './Pages/UserDashboardPage';
import ResetPage from './Pages/ResetPage';


function App() {
  const [dimensions, setDimensions] = useState({ height: window.innerHeight,width: window.innerWidth});

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
    }
  })

  function changeNavbar(width){
    var result;
    if(width <= 600){
     result = <MobileNavbar/>;
    }
    else if(width >= 601 && width <= 825){
      result = <MobileNavbar/>;
    }
    else{
      result = <Navbar/>;
    }
    return result
}
  
  return(
  <Router>
      <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Global Response Development</title>
        <link rel="icon" href="../build/Favicon.ico" />
        <meta name="description" content="Global Response Development" />
      </Helmet>
        {changeNavbar((dimensions.width))}
        <div className="content">
          <Routes>
            <Route exact path="/" element={<HomepagePage />}> </Route>
            <Route exact path="/contact" element={<ContactpagePage />}> </Route>
            <Route exact path="/login" element={<LoginPage />}> </Route>
            <Route exact path="/projects" element={<ProjectsPagepage />}></Route>
            <Route exact path="/support" element={<SupportPagepage />}></Route>
            <Route exact path="/login/createuser" element={<CreateUserPage />}> </Route>
            <Route exact path="/dashboard" element={<UserDashboardPage />}> </Route>
            <Route exact path="/login/reset" element={<ResetPage />}> </Route>
          </Routes>
          
        </div>
      </div>
    </Router>
  );
}

export default App;
