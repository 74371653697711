import "./Homepage.css";
import React, { useState, useRef, useEffect } from "react";
import redwhitelogo from "../../imgs/redwhitelogo.png";
import arrow from "../../imgs/arrow-141-64.png";


function Homepage() {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [dimensions, setDimensions] = React.useState({ height: window.innerHeight,width: window.innerWidth})
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
      }}
    />
  );

  function topFunction() {
    document.body.scrollTo({ top: 0, behavior: "smooth" }); // For Safari
    document.documentElement.scrollTo({ top: 0, behavior: "smooth" }); // For Chrome, Firefox, IE and Opera
  }

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
    }
  })

  function changeTitle(width){
    var result;
    if(width <= 600){
     result = "title_mobile";
    }
    else if(width >= 601 && width <= 825){
      result = "title_tablet"
    }
    else{
      result = "title_browser";
    }
    return result
  }

  function changeUp(width){
    var result;
    if(width <= 600){
     result = "mobileup_button";
    }
    else if(width >= 601 && width <= 825){
      result = "tabletup_button"
    }
    else{
      result = "up_button";
    }
    return result
  }

  function changeMissonlogo(width){
    var result;
    if(width <= 600){
     result = "mobilemission_logo";
    }
    else{
      result = "mission_logo";
    }
    return result
  }

  function changeMissoncontain(width){
    var result;
    if(width <= 600){
     result = "mobilemission_container";
    }
    else{
      result = "mission_container";
    }
    return result
  }

  const missionRef = useRef(null);
  /*const executeScroll = () =>
    missionRef.current.scrollIntoView({ behavior: "smooth" });*/

  return (
    <div className="App">

      <main>
        <div className="page_layout">
        
          <div className={changeTitle(dimensions.width)}>Global Response Development</div>
        </div>
      </main>
      <br/>
      <div>
        <button onClick={topFunction} className={changeUp(dimensions.width)}>
          <img src={arrow} alt="" className="uparrow" />
        </button>
      </div>
      
      <div className="parent" ref={missionRef}>
        <div className="child">
          <img src={redwhitelogo} alt="" className={changeMissonlogo(dimensions.width)} />
        </div>

        <div className="child">
          <div className={changeMissoncontain(dimensions.width)}>
            <div className="mission_title">Mission</div>
            <div className="mission_description">
              <p>
                <p>We approach each project with purpose and focus, delivering
                efficient, user-friendly, secure, and reliable software.</p>

                <p>Our team upholds high standards of craftsmanship, continuously
                improving to provide top-quality solutions. Interactions are
                guided by respect, courtesy, and empathy, building lasting
                relationships based on trust.</p>

                <p>Our mission is to deliver software solutions reflecting our
                values of honor, integrity, and loyalty with professionalism and
                dedication to excellence.</p>
              </p>
            </div>
          </div>
        </div>
      </div>
      {<div>{changeTitle(dimensions.width)} {(dimensions.width)}</div>}
      
    </div>
  );
}

export default Homepage;
