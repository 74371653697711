import * as React from 'react'
import ProjectsPage from '../Components/ProjectsPage/ProjectsPage'

//Creating Homepage
const ProjectsPagepage = () => {

    return (
        <div style={{ height: "100%px" }}>
            <ProjectsPage />
        </div>
    )
}
//Exporting Homepage
export default ProjectsPagepage