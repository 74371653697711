import * as React from 'react'
import Dashboard from "../Components/UserDashboard/userDashboard" 

//Creating Homepage
const UserDashboardPage = () => {

    return (
        <div style={{ height: "100%px" }}>
            <Dashboard />
        </div>
    )
}
//Exporting Homepage
export default UserDashboardPage;