import React,{useState,useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword, signInWithGoogle} from "../../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./login.css";

function Login() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);
  

  //Add Back once fixed: <button className= "login__btn" onClick={() => signInWithEmailAndPassword(auth, email, password)} >Login</button>
    return(
        <div className="parent">
          <div className="child">
          <div className="box">
            <text className="signintext">
                Sign in to Get Access to User Dashboard
            </text>
            <br /><br />
            <Link to="createuser">
                <button className="createbutton">
                    Create a User Account
                 </button>
            </Link>   
          </div>
          </div>
            <div className="child">
              
              <form className="login__container">
                <br/><br/>
                <text className="questionTitles">Email</text>
                <br/>
                <input className="login__textBox" placeholder="Coming Soon, Leave Empty Here" id={email} onChange={(e) => setEmail(e.target.value)} maxLength="50" required type="email"></input>
                <br/>
                <text className="loginquestionTitles">Password</text>
                <br/>
                <input className="login__textBox" placeholder="Use Login with Google Below" id={password} onChange={(e) => setPassword(e.target.value)} maxLength="50" required type="password"></input>
                <br/>
                
                <button className="login__btn login__google" onClick={signInWithGoogle}>
                    Login with Google
                </button>
                <div>
                    <Link to="/login/reset">Forgot Password</Link>
                </div>
              </form>
                
            </div>
            
        </div>
    )
}
export default Login;