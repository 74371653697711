import * as React from 'react'
import Login from '../Components/Login/Login'

//Creating Homepage
const LoginPage = () => {

    return (
        <div style={{ height: "100%px" }}>
            <Login />
        </div>
    )
}
//Exporting Homepage
export default LoginPage