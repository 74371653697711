import * as React from 'react'
import Reset from "../Components/Reset/Reset"

//Creating Homepage
const ResetPage = () => {

    return (
        <div style={{ height: "100%px" }}>
            <Reset />
        </div>
    )
}
//Exporting Homepage
export default ResetPage