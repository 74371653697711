import React from "react";

const ProjectsPage = () =>{

    return(
        <div>
            <h1>Coming Soon</h1>
        </div>
    )
}
export default ProjectsPage;