import { Link } from "react-router-dom";
import React from "react";
import "rsuite/dist/rsuite.min.css";
import './MobileNavbar.css';
import { Popover, Whisper, Dropdown, Button } from "rsuite/";
import menuIcon from '../../imgs/icons8-menu-50.png'

const MobileNavbar = () => {
  
    const renderMenu = ({ left, top, className }, ref) => {
        return (
            <Popover ref={ref} className={className} >
            <Dropdown.Menu title="Menu" >
                <Dropdown.Item eventKey={1}>
                    <Link to="/">Home</Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey={2}>
                    <Link to="/login">Log-In</Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey={3}>
                    <Link to="/projects" >Projects</Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey={4}>
                    <Link to="/contact">Contact</Link>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Popover>
    );
};

  return (
    <nav className="navbar">
      <div className="links">
        <div></div>
      <div style={{ padding: 5 }}>
                <Whisper placement="bottomStart" 
                    trigger="click" speaker={renderMenu}>
                    <Button appearance="primary" color="red">
                    <img src={menuIcon} alt="" className="menuIcon" />
                    </Button>
                </Whisper>
            </div>
      </div>
    <text className="name">Global Response Development</text>
      
    </nav>
  );
}

export default MobileNavbar;