import * as React from 'react'
import CreateUser from '../Components/CreateUser/CreateUser'

//Creating Homepage
const CreateUserPage = () => {

    return (
        <div style={{ height: "100%px" }}>
            <CreateUser />
        </div>
    )
}
//Exporting Homepage
export default CreateUserPage;