import React from "react";
import './ContactPage.css';
import {collection, addDoc} from "firebase/firestore";
import { db } from "../../Firebase";
import redwhitelogo from '../../imgs/redwhitelogo.png'


const ContactPage  = () =>{
  
  const [dimensions, setDimensions] = React.useState({ height: window.innerHeight,width: window.innerWidth})

  async function onSubmit(e) {
    e.preventDefault();
    
    var data ={
    users_Name: document.getElementById("usersName").value,
    users_Email: document.getElementById("usersEmail").value,
    users_Details: document.getElementById("usersMessage").value
    }
    
    var spacing ={
      spacing1: ("\t")
    }

    try{
      const docRef = addDoc(collection(db, "IncomingContacts"), {
      data
    });
    console.log("Document written with ID: ", docRef.id);
    alert('This request was sent successfully.')
  } catch (e) {
    console.error("Error adding document: ", e);
  }
  
  try{
    const docRef = addDoc(collection(db, "mail"), {
      to: ['grdvcontact.hdxsv2@zapiermail.com'],
      message: {
        subject: ("New User Contact (GRDV.org)"),
        text: ('This user reached out:'),
        html: ("Name: " + data.users_Name  + spacing.spacing1	+ " Email: "  + data.users_Email + spacing.spacing1 + "Details: " + data.users_Details),
      }
  });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
  }


  
    React.useEffect(() => {
      function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      
      }
  
      window.addEventListener('resize', handleResize)
  
      return _ => {
        window.removeEventListener('resize', handleResize)
      
      }
    })
    
    function changeform(width){
        var result;
        if(width <= 600){
         result = "mobileform";
        }
        else if(width >= 601 && width <= 825){
          result = "tabletform"
        }
        else{
          result = "form";
        }
        return result
    }
    
    function changeSubmit(width){
        var result;
        if(width <= 600){
         result = "mobileSubmit";
        }
        else if(width >= 601 && width <= 825){
          result = "tabletSubmit"
        }
        else{
          result = "Submit";
        }
        return result
    }

    return(
        <body className="parent">
          <div className="child">
          <img src={redwhitelogo} alt="" className="mission_logo" />
          </div>
            <div className="child">
              
              <form onSubmit={onSubmit} className="form">
                <br/>
                <br/>
                <br/>
                <text className="questionTitles">Name</text>
                <br/>
                <input placeholder="Name Here" id="usersName" maxLength="50" required type="text"></input>
                <br/>
                <text className="questionTitles">Email</text>
                <br/>
                <input placeholder="Email Here" id="usersEmail" maxLength="50" required type="email"></input>
                <br/>
                <text className="questionTitles">Details</text>
                <br/>
                <textarea rows="5" cols="60" name="Details" placeholder="Details Here" type="text" id="usersMessage" maxLength="350" required className="textbox"></textarea>
                <br/>
                <button className= "submit" value={"Submit"}>SUBMIT</button>
              </form>
                
            </div>
        </body>
    )
}
export default ContactPage;