import { Link } from "react-router-dom";
import './Navbar.css';
import React, { useState } from "react";

const Navbar = () => {
  const [dimensions, setDimensions] = React.useState({ height: window.innerHeight,width: window.innerWidth})

  function changeLinks(width){
    var result;
    if(width <= 600){
     result = "mobilenavbar";
    }
    else if(width >= 601 && width <= 825){
      result = "tabletnavbar"
    }
    else{
      result = "navbar";
    }
    return result
  }

  return (
    <nav className="navbar">
      

      <Link to="/"><h1>Global Response Development</h1></Link>
      <div className="links">
        <Link to="/login"
        style={{color: 'black'}}
        >Log-In</Link>
        <Link to="/projects" style={{
          color: 'white', 
          backgroundColor: '#FF5757',
          borderRadius: '8px'
        }}>Projects</Link>
        <Link to="/contact" style={{ 
          color: 'white', 
          backgroundColor: '#FF5757',
          borderRadius: '8px' 
        }}>Contact</Link>
      </div>
    </nav>
  );
}
 
export default Navbar;