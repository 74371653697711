import * as React from 'react'
import ContactPage from '../Components/ContactPage/ContactPage'

//Creating Homepage
const ContactpagePage = () => {

    return (
        <body>
            <div style={{ height: "100%" }}>
                <ContactPage />
            </div>
        </body>
    )
}
//Exporting Homepage
export default ContactpagePage