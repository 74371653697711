import * as React from 'react'
import Homepage from "../Components/Homepage/Homepage"

//Creating Homepage
const HomepagePage = () => {

    return (
        <div style={{ height: "100%px" }}>
            <Homepage />
        </div>
    )
}
//Exporting Homepage
export default HomepagePage