import * as React from 'react'
import SupportPage from '../Components/SupportPage/SupportPage'

//Creating Homepage
const SupportPagepage = () => {

    return (
        <div style={{ height: "100%px" }}>
            <SupportPage />
        </div>
    )
}
//Exporting Homepage
export default SupportPagepage